import React from 'react';
import { Shimmer, Stack } from '@fluentui/react';

type ShimmersListProps = {
	count?: number;
};

const ShimmersList: React.FC<ShimmersListProps> = (props) => {
	const defaultNumberOfShimmers = 42;
	return (
		<Stack data-testid="loading" tokens={{ childrenGap: 8 }}>
			{[...Array(props.count ?? defaultNumberOfShimmers)].map(
				(value, index) => (
					<Shimmer key={`shimmer-${index}`} />
				)
			)}
		</Stack>
	);
};

export default ShimmersList;
