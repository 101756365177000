import React from 'react';
import {
	DetailsList as FluentDetailsList,
	IColumn as FluentIColumn,
	IDetailsListProps as FluentIDetailsListProps,
	Stack,
	mergeStyleSets,
} from '@fluentui/react';

export type IColumn = FluentIColumn & { fieldName: string };
export type IDetailsListProps = FluentIDetailsListProps & {
	columns: IColumn[];
};

const classes = mergeStyleSets({
	column: {
		minHeight: '100%',
	},
});

export const DetailsList: React.FC<
	IDetailsListProps & { 'data-testid': string; dataTestIdProperty?: string }
> = ({ 'data-testid': dataTestId, dataTestIdProperty, ...props }) => {
	return (
		<div data-testid={dataTestId}>
			<FluentDetailsList
				onRenderRow={(props, defaultRender?): JSX.Element => {
					return (
						<div data-testid={`row:${props?.item[dataTestIdProperty || 'id']}`}>
							{defaultRender && defaultRender(props)}
						</div>
					);
				}}
				onRenderItemColumn={(item, _index, column) => {
					if (column?.fieldName === undefined) {
						return null;
					}
					return (
						<Stack verticalAlign="center" className={classes.column}>
							<Stack.Item data-testid={`column:${column?.fieldName}`}>
								<span>{item[column.fieldName]}</span>
							</Stack.Item>
						</Stack>
					);
				}}
				{...props}
			/>
		</div>
	);
};
