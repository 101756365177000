import Keycloak from 'keycloak-js';

export const isAuthMocked = process.env.REACT_APP_USE_KEYCLOAK_MOCK === 'true';

export interface Config {
	baseUrl?: string;
	keycloak: Keycloak.KeycloakConfig;
	cloudPortalApiUrl: string;
}

export const defaultConfig: Config = {
	baseUrl: process.env.REACT_APP_BASE_URL,
	keycloak: {
		url: process.env.REACT_APP_KEYCLOAK_URL,
		realm: process.env.REACT_APP_KEYCLOAK_REALM || '',
		clientId: process.env.REACT_APP_KEYCLOAK_CLIENT || '',
	},
	cloudPortalApiUrl: process.env.REACT_APP_CLOUD_PORTAL_API_BASE_URL || '',
};

export const dynamicConfigUrl =
	'/config.json?' + new Date().getTime().toString();
