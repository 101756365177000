import React from 'react';
import { Badge, BadgeColor } from '@ysoft/fluent-components';
import { Api } from '../../../types/api';
import { useIntl } from '@ysoft/react-intl';
import { useApiContext } from '../../libs/apiContext';
import useSWR from 'swr';
import {
	TooltipHost,
	TooltipDelay,
	DirectionalHint,
	ITooltipProps,
	ITooltipHostStyles,
} from '@fluentui/react';

const ApplicationStatus: React.FC<{
	device: Api.Device;
	deviceApplicationUpdate: (
		device: Api.Device,
		application: Api.Application
	) => Promise<Api.DevicesResponse[] | undefined>;
}> = (props) => {
	const { t, currentLocale } = useIntl();
	const { omniPlatformApiInstance: api } = useApiContext();
	let badgeColor: BadgeColor;
	let text: string;
	let lastStatusChange: string | undefined = undefined;

	const installedTone: BadgeColor = 'success';
	const installedText = t(
		'omni-bridge-devices-list__application-status-installed'
	);

	switch (
		props.device.installedApplications[0]?.installationStatus?.status?.toLowerCase()
	) {
		case 'installed':
			badgeColor = installedTone;
			text = installedText;
			break;
		case 'installing':
			badgeColor = 'warning';
			text = t('omni-bridge-devices-list__application-status-installing');
			break;
		default: {
			if (
				props.device.installedApplications?.length > 0 &&
				!props.device.installedApplications[0].installationStatus
			) {
				badgeColor = installedTone;
				text = installedText;
			} else {
				badgeColor = 'default';
				text = t('omni-bridge-devices-list__application-status-notAvailable');
			}
			break;
		}
	}

	lastStatusChange =
		props.device.installedApplications[0]?.installationStatus?.lastStatusChange;

	lastStatusChange = lastStatusChange
		? new Date(lastStatusChange?.toString()).toLocaleString(currentLocale)
		: undefined;

	const applicationStatusFetcher = (url: string) =>
		api
			.get<Api.ApplicationsResponse>(url)
			.then((res) => res.data)
			.then((response) => {
				if (
					props.device.installedApplications[0]?.installationStatus?.status?.toLowerCase() !==
					response?.items[0]?.installationStatus?.status?.toLowerCase()
				) {
					props.deviceApplicationUpdate(props.device, response.items[0]);
				}
				return response;
			})
			.catch((error) => {
				console.warn(
					'Polling for devices failed, but it is ok, we will try again.'
				);
				return error;
			});

	useSWR<Api.ApplicationsResponse>(
		props.device.installedApplications[0]?.installationStatus?.status?.toLowerCase() ===
			'installing'
			? `/devices/${props.device.id}/applications`
			: null,
		applicationStatusFetcher,
		{ refreshInterval: 10000 }
	);

	const tooltipProps: ITooltipProps = {
		onRenderContent: () => <div>{lastStatusChange}</div>,
	};

	const tooltipId = 'tooltip';
	const hostStyles: Partial<ITooltipHostStyles> = {
		root: { display: 'inline-block' },
	};

	return (
		<TooltipHost
			tooltipProps={tooltipProps}
			delay={TooltipDelay.zero}
			id={tooltipId}
			directionalHint={DirectionalHint.bottomCenter}
			styles={hostStyles}
			hidden={lastStatusChange ? false : true}
		>
			<Badge color={badgeColor}>{text}</Badge>
		</TooltipHost>
	);
};
export default ApplicationStatus;
