import React, { useState } from 'react';
import { useIntl } from '@ysoft/react-intl';
import { PrimaryButton, TooltipHost } from '@fluentui/react';
import ProgressButton from '../general-components/ProgressButton';
import { useApiContext } from '../../libs/apiContext';
import { Api } from '../../../types/api';

export type InstallationState = 'success' | 'error';

const InstallApplicationButton: React.FC<{
	application?: Api.Application;
	device?: Api.Device;
	installedApplication?: Api.Application;
	installationDone: (
		device: Api.Device,
		application: Api.Application,
		state: InstallationState
	) => void;
}> = (props) => {
	const { t } = useIntl();
	const { omniPlatformApiInstance: api } = useApiContext();

	const [installationState, setInstallationState] = useState<
		'idle' | 'inProgress'
	>('idle');

	const isApplicationAlreadyInstalled =
		props.installedApplication?.id === props.application?.id;
	const disabled = !props.application || isApplicationAlreadyInstalled;

	const installApplication = (
		device: Api.Device,
		application: Api.Application
	) => {
		setInstallationState('inProgress');
		api
			.put(`/devices/${device.id}/applications/${application.id}/`, {})
			.then(() => {
				props.installationDone(device, application, 'success');
			})
			.catch(() => {
				props.installationDone(device, application, 'error');
				setInstallationState('idle');
			});
	};

	const tooltipContent =
		props.installedApplication && isApplicationAlreadyInstalled
			? t('install-application-modal__application-already-installed')
			: !props.installedApplication
			? t('install-application-modal__you-need-to-select-application')
			: '';
	return installationState === 'inProgress' ? (
		<ProgressButton>
			{t('install-application-modal__installing-application-button')}
		</ProgressButton>
	) : (
		<TooltipHost content={tooltipContent} id="install-disabled-tooltip">
			<PrimaryButton
				data-testid="install-application-modal__install-application-button"
				disabled={disabled}
				onClick={() => {
					if (props.device && props.application) {
						installApplication(props.device, props.application);
					}
				}}
			>
				{t('install-application-modal__install-application-button')}
			</PrimaryButton>
		</TooltipHost>
	);
};

export default InstallApplicationButton;
