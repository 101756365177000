import React from 'react';
import { mergeStyleSets, Stack } from '@fluentui/react';
import { useIntl } from '@ysoft/react-intl';

const classNames = mergeStyleSets({
	not_found__page: {
		height: '10rem',
	},
});

const NotFoundPage: React.FC = () => {
	const { t } = useIntl();

	return (
		<Stack
			data-testid="not-found__page"
			horizontalAlign={'center'}
			verticalAlign={'center'}
			className={classNames.not_found__page}
		>
			<h1>{t('general__page-not-found')}</h1>
		</Stack>
	);
};

export default NotFoundPage;
