import React from 'react';
import { useLocation } from 'react-router-dom';
import { Nav, INavLink, INavLinkGroup, IRenderFunction } from '@fluentui/react';
import { useIntl } from '@ysoft/react-intl';
import useApp from '../../useApp';
import { useUserContext } from '../../libs/userContext';

export interface IMenuProps {
	isMobile: boolean;
}

const Menu: React.FC<IMenuProps> = (props) => {
	const { operations, classes } = useApp();
	const { t } = useIntl();
	const location = useLocation();
	const { isPartner: userIsPartner, hasTenantId: userHasTenantId } =
		useUserContext();

	const urls = {
		devices: '/devices',
	};

	const baseNavLinks: INavLink[] = [];
	if (userHasTenantId || userIsPartner) {
		baseNavLinks.push({
			name: t('omni-bridges'),
			url: urls.devices,
			key: urls.devices,
			icon: 'Devices2',
			automationId: 'omni-bridges__nav-link',
			'data-testid': 'devices-page__nav-link',
		});
	}

	const navLinks: INavLinkGroup[] = [
		{
			links: baseNavLinks,
		},
	];

	const mobileNavLinks: INavLinkGroup[] = [
		{
			name: t('menu__mobile-heading'),
			collapseByDefault: true,
			links: baseNavLinks,
		},
	];

	const onRenderLink: IRenderFunction<INavLink> = (
		props?: INavLink,
		defaultRender?: (props?: INavLink) => JSX.Element | null
	) => {
		return (
			<div data-testid={props && props.automationId}>
				{defaultRender && defaultRender(props)}
			</div>
		);
	};

	const menuRenderer = (
		<div data-testid="menu">
			<Nav
				groups={navLinks}
				selectedKey={location.pathname}
				className={classes.navClass}
				styles={classes.commonNavStylesStyle}
				onLinkClick={operations.navigationLinkClick}
				onRenderLink={onRenderLink}
			/>
		</div>
	);

	const mobileMenuRenderer = (
		<div data-testid="menu-mobile">
			<Nav
				groups={mobileNavLinks}
				selectedKey={location.pathname}
				className={classes.mobileNavClass}
				styles={classes.commonNavStylesStyle}
				onLinkClick={operations.navigationLinkClick}
			/>
		</div>
	);

	return props.isMobile ? mobileMenuRenderer : menuRenderer;
};

export default Menu;
