import { useKeycloak } from '@react-keycloak/web';
import { sign } from 'jsonwebtoken';
import jwtTokenPayload from './tokenPayload.json';
import { isAuthMocked } from '../config/config';

const changeToken = () => {
	const data: Partial<typeof jwtTokenPayload> = jwtTokenPayload;
	const mockedTenantId = window.localStorage.getItem('mockedTenantId');
	if (mockedTenantId === 'NOT_DEFINED') {
		delete data.tenant_id;
	} else {
		data.tenant_id = mockedTenantId ? mockedTenantId : data.tenant_id;
	}
	data.tenant_name =
		window.localStorage.getItem('mockedTenantName') || data.tenant_name;
	const mockedPartnerId = window.localStorage.getItem('mockedPartnerId');
	if (mockedPartnerId === 'NOT_DEFINED') {
		delete data.partner_id;
	} else {
		data.partner_id = mockedPartnerId
			? parseInt(mockedPartnerId)
			: data.partner_id;
	}
	data.roles =
		window.localStorage.getItem('mockedRoles')?.split(',') || data.roles;
	return sign(data, 'shhhhh', { expiresIn: '86400000' });
};

const mockedAuth = {
	initialized: true,
	keycloak: {
		authenticated: true,
		login: async () => {
			return;
		},
		logout: async () => {
			return;
		},
		loadUserInfo: async () => {
			return {
				preferred_username: 'test-user',
				given_name: 'Test',
				family_name: 'User',
				tenant_id: jwtTokenPayload.tenant_id,
				tenant_name: jwtTokenPayload.tenant_name,
				email: 'Test.User@fictum-company.com',
				partner_id: jwtTokenPayload.partner_id,
				roles: jwtTokenPayload.roles,
			};
		},
		token: changeToken(),
	},
};

if (isAuthMocked) {
	window.onstorage = () => {
		mockedAuth.keycloak.token = changeToken();
	};
}

const useAuth = () => {
	if (isAuthMocked) {
		return mockedAuth;
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useKeycloak();
};

const ExportFunction = (): ReturnType<typeof useAuth> => useAuth();
export default ExportFunction;
