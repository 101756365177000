import {
	CheckboxVisibility,
	DetailsListLayoutMode,
	mergeStyleSets,
	SelectionMode,
	Stack,
} from '@fluentui/react';
import React, { useContext, useState } from 'react';
import { apiContext } from '../../libs/apiContext';
import { useIntl } from '@ysoft/react-intl';
import { DetailsList, IColumn } from '../general-components/DetailsList';
import ShimmersList from '../general-components/ShimmersList';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import InstallApplicationModal from '../install-application/InstallApplicationModal';
import useInfiniteScrolling from '../../hooks/useInfiniteScrolling';
import useSearchedTerm from '../../hooks/useSearchedTerm';
import { ErrorMessageBar } from '../general-components/ErrorMessageBar';
import HeadingWithSearch from '../HeadingWithSearch';
import { Api } from '../../../types/api';
import useApp from '../../useApp';
import ApplicationStatus from './ApplicationStatus';
import { useCallback } from 'react';
import SelectTenantPrompt from './SelectCustomerPrompt';

const classes = mergeStyleSets({
	search: {
		width: '100%',
		maxWidth: '20rem',
	},
	fullHeight: {
		minHeight: '100%',
	},
});

const DevicesList: React.FC = () => {
	const appendix = '&searchFields=deviceId,ipAddress';
	const { t } = useIntl();
	const { classes: appClasses } = useApp();
	const { omniPlatformApiInstance: api } = useContext(apiContext);
	const { searchedTerm, setSearchedTerm } = useSearchedTerm(appendix);

	const queryAppendSearchFieldsIfTermNotEmpty = (term: string) => {
		if (!term) {
			return term;
		} else {
			return `${term}&searchFields=deviceId,ipAddress`;
		}
	};
	const { swrData, loadMoreTriggerRef, hasReachedEnd } =
		useInfiniteScrolling<Api.DevicesResponse>(
			queryAppendSearchFieldsIfTermNotEmpty(searchedTerm),
			'/devices'
		);
	const [installApplicationModalInfo, setInstallApplicationModalInfo] =
		useState<{
			isOpen: boolean;
			device?: Api.Device;
		}>({
			isOpen: false,
		});

	const columns: IColumn[] = [
		{
			key: 'deviceInfo',
			name: t('omni-bridge-devices-list__device-info'),
			fieldName: 'deviceInfo',
			minWidth: 100,
			isResizable: false,
			isMultiline: true,
			onRender: (device: Api.Device) => (
				<Stack data-testid="column:deviceInfo">
					<Stack.Item data-testid="omni-bridge-devices-list__deviceId">
						<strong>{device.deviceId}</strong>
					</Stack.Item>
					{device.networkStatus && (
						<Stack.Item data-testid="omni-bridge-devices-list__deviceip">
							{t('omni-bridge-devices-list__device-ip', {
								ip: device.networkStatus.ipAddress,
							})}
						</Stack.Item>
					)}
				</Stack>
			),
		},
		{
			key: 'applicationName',
			name: t('omni-bridge-devices-list__application-name'),
			fieldName: 'applicationName',
			minWidth: 200,
			onRender: (device: Api.Device) => (
				<Stack verticalAlign="center" className={classes.fullHeight}>
					<Stack.Item data-testid="column:applicationName">
						<span>
							{device.installedApplications &&
								device.installedApplications[0]?.name}
						</span>
					</Stack.Item>
				</Stack>
			),
			isCollapsible: true,
		},
		{
			key: 'applicationStatus',
			name: t('omni-bridge-devices-list__application-status'),
			fieldName: 'applicationStatus',
			minWidth: 150,
			onRender: (device: Api.Device) => (
				<Stack
					verticalAlign="center"
					data-testid="column:applicationStatus"
					className={classes.fullHeight}
				>
					<Stack.Item>
						<ApplicationStatus
							device={device}
							deviceApplicationUpdate={deviceApplicationUpdate}
						/>
					</Stack.Item>
				</Stack>
			),
			isCollapsible: true,
		},
		{
			key: 'install',
			name: '',
			fieldName: 'install',
			minWidth: 150,
			onRender: (device: Api.Device) => (
				<PrimaryButton
					data-testid="omni-bridge-devices-list__button-install"
					onClick={() =>
						setInstallApplicationModalInfo({ isOpen: true, device: device })
					}
				>
					{t('omni-bridge-devices-list__button-install')}
				</PrimaryButton>
			),
		},
	];

	const devices = swrData.data
		? swrData.data.map((response) => response.items).flat()
		: [];

	const deviceApplicationUpdate = useCallback(
		(device: Api.Device, application: Api.Application) => {
			return swrData.mutate(
				(devicesResponse) =>
					devicesResponse?.map((devices) => ({
						nextPageInfo: devices.nextPageInfo,
						items: devices.items.map((deviceItem) =>
							deviceItem.deviceId === device.deviceId
								? { ...deviceItem, installedApplications: [{ ...application }] }
								: deviceItem
						),
					})),
				false
			);
		},
		[swrData]
	);

	const installApplication = useCallback(
		(device: Api.Device, application: Api.Application) => {
			deviceApplicationUpdate(device, application).then(() => {
				api
					.get<Api.ApplicationsResponse>(`/devices/${device.id}/applications`)
					.then((response) => {
						deviceApplicationUpdate(device, response.data.items[0]);
					})
					.catch((error) => {
						console.warn(
							'Failed to get devices applications. Setting values from user input.'
						);
						deviceApplicationUpdate(device, {
							...application,
							installationStatus: {
								status: 'installing',
								lastStatusChange: '',
							},
						});
					});
			});
		},
		[api, deviceApplicationUpdate]
	);

	return (
		<Stack
			className={appClasses.contentClass}
			tokens={{ childrenGap: 8 }}
			data-testid="devices__page"
		>
			<InstallApplicationModal
				onDismiss={() => setInstallApplicationModalInfo({ isOpen: false })}
				isOpen={installApplicationModalInfo.isOpen}
				device={installApplicationModalInfo.device}
				installationSuccess={installApplication}
			/>
			<Stack
				horizontal
				wrap
				verticalAlign="center"
				tokens={{
					childrenGap: 16,
				}}
			>
				<HeadingWithSearch
					data-testid={'devices-list__search-box'}
					HeadingTitle={t('omni-bridges')}
					SearchBoxPlaceholder={t(
						'omni-bridge-devices-list__search-box__placeholder'
					)}
					searchedTerm={searchedTerm}
					setSearchedTerm={setSearchedTerm}
				/>
			</Stack>
			{swrData.error ? (
				swrData.error.response?.data?.specificStatusCode === '404.05' ? (
					<SelectTenantPrompt />
				) : (
					<ErrorMessageBar error={swrData.error.response} />
				)
			) : (
				<>
					{devices.length === 0 ? (
						<>
							{swrData.isValidating ? (
								<ShimmersList />
							) : (
								<p data-testid="no-devices">
									{t('omni-bridge-devices-list__empty-list')}
								</p>
							)}
						</>
					) : (
						<>
							<DetailsList
								data-testid="ob-devices-list"
								items={devices}
								columns={columns}
								setKey="set"
								checkboxVisibility={CheckboxVisibility.hidden}
								selectionMode={SelectionMode.none}
								layoutMode={DetailsListLayoutMode.justified}
								styles={{ root: { marginBottom: '2rem' } }}
							/>
							{swrData.isValidating && <ShimmersList />}
						</>
					)}
					{!hasReachedEnd && !swrData.isValidating && (
						<div
							data-testid="bottom-boundary"
							ref={loadMoreTriggerRef}
						/>
					)}
				</>
			)}
		</Stack>
	);
};

export default DevicesList;
