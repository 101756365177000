import React from 'react';
import DevicesList from './components/devices-list/DevicesList';
import { ContentWrapper } from './components/ContentWrapper';
import { useIntl } from '@ysoft/react-intl';
import useAuth from './libs/auth';
import { Header } from './components/header/Header';
import { Stack } from '@fluentui/react';
import useApp from './useApp';
import Menu from './components/menu/Menu';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useUserContext } from './libs/userContext';
import NotFoundPage from './components/NotFoundPage';
import NoAccessPage from './components/NoAccessPage';

const DevicesPageLink = '/devices';
const NoAccessPageLink = '/no-access';

const App: React.FC = () => {
	const { t } = useIntl();
	const { classes } = useApp();
	const { keycloak } = useAuth();
	const { isPartner: userIsPartner, hasTenantId: userHasTenantId } =
		useUserContext();
	
	return keycloak.authenticated ? (
		<div className={classes.appClass}>
			<title>{t('app__title')}</title>
			<header>
				<Header />
			</header>

			<Menu isMobile={true} />

			<Stack horizontal wrap>
				<Stack.Item disableShrink className={classes.navStackItemClass}>
					<Menu isMobile={false} />
				</Stack.Item>
				<Stack.Item className={classes.contentStackItemClass}>
					<ContentWrapper className={classes.wrapperClass}>
						<Routes>
							<Route
								path="/"
								element={<Navigate to={DevicesPageLink} />}
							/>
							<Route
								path={DevicesPageLink}
								element={
									userHasTenantId || userIsPartner ? (
										<DevicesList />
									) : (
										<NoAccessPage />
									)
								}
							/>
							<Route path={NoAccessPageLink} element={<NoAccessPage />} />
							<Route path="*" element={<NotFoundPage />} />
						</Routes>
					</ContentWrapper>
				</Stack.Item>
			</Stack>
		</div>
	) : (
		<div />
	);
};

export default App;
