import React from 'react';
import { IconButton, mergeStyleSets, Stack } from '@fluentui/react';

type ModalHeaderProps = {
	title: string;
	onDismiss: () => void;
};

const classes = mergeStyleSets({
	header: {
		marginBlockStart: '0',
	},
});

const ModalHeader: React.FC<ModalHeaderProps> = (props) => {
	return (
		<Stack horizontal horizontalAlign="space-between">
			<h2 className={classes.header} data-testid="modal-header__title">
				{props.title}
			</h2>
			<IconButton
				data-testid="cancel-icon"
				iconProps={{ iconName: 'Cancel' }}
				onClick={props.onDismiss}
			/>
		</Stack>
	);
};

export default ModalHeader;
