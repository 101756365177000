import React, { useState, useMemo } from 'react';
import { useIntl } from '@ysoft/react-intl';
import {
	DefaultButton,
	ISelection,
	MessageBar,
	MessageBarType,
	Selection,
	SelectionMode,
	Stack,
	mergeStyleSets,
} from '@fluentui/react';
import DeviceDetail from '../devices-list/DeviceDetail';
import useSWR from 'swr';
import { useApiContext } from '../../libs/apiContext';
import { DetailsList, IColumn } from '../general-components/DetailsList';
import ShimmersList from '../general-components/ShimmersList';
import { ErrorMessageBar } from '../general-components/ErrorMessageBar';
import InstallApplicationButton, {
	InstallationState,
} from './InstallApplicationButton';
import { Api } from '../../../types/api';

const classes = mergeStyleSets({
	scrollableContent: {
		maxHeight: 'calc(min(40rem, 100vh - 30rem))',
		minHeight: '5rem',
		overflow: 'auto',
	},
});

const InstallApplicationSelectApplication: React.FC<{
	onDismiss: () => void;
	device?: Api.Device;
	installationSuccess: (
		device: Api.Device,
		application: Api.Application
	) => void;
}> = (props) => {
	const { t } = useIntl();
	const { omniPlatformApiInstance: api } = useApiContext();

	const applicationsFetcher = (url: string) =>
		api.get(url).then((res) => res.data);
	const { data: applicationsList, error: applicationsFetcherError } =
		useSWR<Api.ApplicationsResponse>('/applications', applicationsFetcher);

	const [selectedApplication, setSelectedApplication] = useState<
		Api.Application | undefined
	>(
		props.device?.installedApplications &&
			props.device?.installedApplications[0]
	);
	const [installationState, setInstallationState] =
		useState<InstallationState>();

	const items = applicationsList?.items;
	const selection = useMemo(() => {
		return new Selection({
			items: items,
			onSelectionChanged: () => {
				setSelectedApplication(selection.getSelection()[0] as Api.Application);
				setInstallationState(undefined);
			},
			getKey: (application: Api.Application) => application.id,
		});
	}, [items, setSelectedApplication, setInstallationState]);
	if (
		selectedApplication &&
		selection.getSelection()[0]?.id !== selectedApplication.id
	) {
		selection.setKeySelected(selectedApplication.id, true, false);
	}

	const detailsListColumns: IColumn[] = [
		{
			key: 'applicationName',
			name: t('install-application-modal__application-name'),
			fieldName: 'name',
			minWidth: 200,
			isResizable: false,
		},
	];

	return (
		<>
			<Stack tokens={{ childrenGap: 20 }}>
				<DeviceDetail device={props.device} />
				<p>{t('install-application-modal__select-application')}</p>
			</Stack>
			<div className={classes.scrollableContent}>
				{applicationsFetcherError ? (
					<ErrorMessageBar error={applicationsFetcherError} />
				) : (
					<>
						{!applicationsList ? (
							<ShimmersList count={10} />
						) : applicationsList.items.length === 0 ? (
							<p>{t('install-application-modal__applications-empty-list')}</p>
						) : (
							<>
								<DetailsList
									data-testid="install-application-modal__applications-list"
									items={applicationsList.items}
									columns={detailsListColumns}
									setKey="banana"
									selection={selection as unknown as ISelection}
									selectionMode={SelectionMode.single}
									onRenderCheckbox={(props, defaultRender) => (
										<div data-testid="column:checkbox">
											<div
												data-testid={`checkbox:${
													props?.checked ? 'selected' : 'not-selected'
												}`}
											>
												{defaultRender && defaultRender(props)}
											</div>
										</div>
									)}
								/>
							</>
						)}
					</>
				)}
			</div>
			{props.device?.installedApplications &&
				props.device?.installedApplications.length > 0 && (
					<MessageBar messageBarType={MessageBarType.info}>
						{t(
							'install-application-modal__uninstalling-application-warning-message',
							{ applicationName: props.device?.installedApplications[0].name }
						)}
					</MessageBar>
				)}
			{installationState === 'error' && (
				<ErrorMessageBar
					errorMessage={t('install-application-modal__installation-failed', {
						applicationName: selectedApplication?.name,
					})}
				/>
			)}
			<Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 8 }}>
				<DefaultButton
					data-testid="omni-close"
					text={t('general__button-close')}
					onClick={props.onDismiss}
				/>
				<InstallApplicationButton
					application={selectedApplication}
					device={props.device}
					installedApplication={
						props.device?.installedApplications
							? props.device?.installedApplications[0]
							: undefined
					}
					installationDone={(
						device: Api.Device,
						application: Api.Application,
						state: InstallationState
					) => {
						if (state === 'success') {
							props.installationSuccess(device, application);
						} else if (state === 'error') {
							setInstallationState('error');
						}
					}}
				/>
			</Stack>
		</>
	);
};

export default InstallApplicationSelectApplication;
