import React, { createContext, useContext } from 'react';
import axios, { AxiosInstance } from 'axios';
import useAuth from './auth';
import { useConfigContext } from '../config/configContext';
import { useUserContext } from './userContext';
import { loadFromLocalStorage } from './localStorageHelper';
import { currentTenantLocalStorageKey } from '../support/constants';
import { Api } from '../../types/api';

const defaultAxiosInstance = axios.create({ baseURL: 'not-loaded' });

export const apiContext = createContext<Api.ApiContext>({
	omniPlatformApiInstance: defaultAxiosInstance,
	cloudPortalApiInstance: defaultAxiosInstance,
});

export const useApiContext = (): Api.ApiContext => useContext(apiContext);

const ApiProvider: React.FC = (props) => {
	const { config } = useConfigContext();
	const userContext = useUserContext();
	const { initialized, keycloak } = useAuth();
	const apiInstance = axios.create({
		baseURL: `${config.baseUrl}/api/`,
	});
	const cloudApiInstance = axios.create({
		baseURL: `${config.cloudPortalApiUrl}/`,
	});

	const configureInstance = (instance: AxiosInstance) => {
		instance.interceptors.request.use((config) => {
			if (!config.headers) {
				config.headers = {};
			}
			config.headers.Authorization = `Bearer ${keycloak.token}`;
			const localStorageValue = loadFromLocalStorage(
				`${currentTenantLocalStorageKey}-${userContext.preferred_username}`
			);
			if (localStorageValue) {
				config.headers.RequestedTenant = localStorageValue;
			}
			return config;
		});
	};

	if (initialized && keycloak && keycloak.token) {
		configureInstance(apiInstance);
		configureInstance(cloudApiInstance);
	}

	return (
		<apiContext.Provider
			value={{
				omniPlatformApiInstance: apiInstance,
				cloudPortalApiInstance: cloudApiInstance,
			}}
		>
			{props.children}
		</apiContext.Provider>
	);
};

export default ApiProvider;
