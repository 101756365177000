import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const useSearchedTerm = (
	appendix = '',
	queryParameter = 'q',
	onSearchedTermNotSet?: () => void,
	onSearchedTermSet?: (searchTerm: string) => void
) => {
	const navigate = useNavigate();
	const location = useLocation();

	const [searchedTerm, setSearchedTerm] = useState(
		new URLSearchParams(location.search).get(queryParameter) ?? ''
	);

	useEffect(() => {
		if (searchedTerm === '') {
			navigate(location.pathname);
			if (onSearchedTermNotSet) {
				onSearchedTermNotSet();
			}
		} else {
			navigate({
				pathname: location.pathname,
				search: `?${queryParameter}=${searchedTerm}${appendix}`,
			});
			if (onSearchedTermSet) {
				onSearchedTermSet(searchedTerm);
			}
		}
	}, [
		navigate,
		searchedTerm,
		queryParameter,
		onSearchedTermNotSet,
		onSearchedTermSet,
		location.pathname,
		appendix,
	]);

	return {
		searchedTerm,
		setSearchedTerm,
	};
};

const ExportFunction = (
	...params: Parameters<typeof useSearchedTerm>
): ReturnType<typeof useSearchedTerm> => useSearchedTerm(...params);
export default ExportFunction;
