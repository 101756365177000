import React from 'react';
import { Stack } from '@fluentui/react';
import { Devices2Icon } from '@fluentui/react-icons-mdl2';
import { Api } from '../../../types/api';

const DeviceDetail: React.FC<{
	device?: Api.Device;
}> = (props) => {
	return (
		<Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
			<Devices2Icon />
			<span>{props.device?.deviceId}</span>
		</Stack>
	);
};

export default DeviceDetail;
