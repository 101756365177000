import React from 'react';
import { useIntl } from '@ysoft/react-intl';
import { DefaultButton, Stack, mergeStyleSets } from '@fluentui/react';

const classes = mergeStyleSets({
	successImageStackItem: {
		width: '100%',
		maxWidth: '20rem',
		textAlign: 'center',
	},
	successCongratulationStackItem: {
		fontWeight: 'bold',
		fontSize: '110%',
	},
});

const InstallApplicationSuccessStep: React.FC<{
	onDismiss: () => void;
	applicationName: string;
}> = (props) => {
	const { t } = useIntl();
	return (
		<>
			<Stack tokens={{ childrenGap: 20 }} horizontalAlign="center">
				<Stack.Item className={classes.successImageStackItem} align="center">
					<img
						src="/assets/OmniSuccess.svg"
						alt="Success tick"
						data-testid="omni-success"
					/>
				</Stack.Item>
				<Stack.Item className={classes.successCongratulationStackItem}>
					{t('install-application-modal__success-congratulations')}
				</Stack.Item>
				<Stack.Item>
					{t('install-application-modal__success-information', {
						applicationName: props.applicationName,
					})}
				</Stack.Item>
			</Stack>
			<Stack horizontalAlign="end">
				<DefaultButton
					data-testid="omni-close"
					text={t('general__button-close')}
					onClick={props.onDismiss}
				/>
			</Stack>
		</>
	);
};

export default InstallApplicationSuccessStep;
