export function loadFromLocalStorage(
	localStorageKey: string
): string | undefined {
	if (typeof window !== 'undefined') {
		const valueFromStorage = localStorage.getItem(localStorageKey);
		if (valueFromStorage !== null) {
			return valueFromStorage;
		}
	}
	return undefined;
}

export function loadFromLocalStorageOrSaveDefault(
	localStorageKey: string,
	defaultValue: string
): string {
	let value = defaultValue;
	if (typeof window !== 'undefined') {
		const valueFromStorage = localStorage.getItem(localStorageKey);
		if (valueFromStorage === null) {
			localStorage.setItem(localStorageKey, value);
		} else {
			value = valueFromStorage;
		}
	}

	return value;
}

export function saveToLocalStorage(
	localStorageKey: string,
	value: string
): void {
	if (typeof window !== 'undefined') {
		localStorage.setItem(localStorageKey, value);
	}
}
