import React from 'react';
import { Stack, StackItem } from '@fluentui/react';
import { ErrorMessageBar } from './general-components/ErrorMessageBar';
import { useIntl } from '@ysoft/react-intl';

const NoAccessPage: React.FC = () => {
	const { t } = useIntl();

	return (
		<Stack>
			<StackItem data-testid="no-access__page">
				<ErrorMessageBar
					errorMessage={t('error__no-access', {
						objectOfRestriction: 'this page',
					})}
				/>
			</StackItem>
		</Stack>
	);
};

export default NoAccessPage;
