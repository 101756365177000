import React from 'react';
import { IMessageBarProps, MessageBar, MessageBarType } from '@fluentui/react';
import { useCallback } from 'react';
import { useIntl } from '@ysoft/react-intl';
import { Api } from '../../../types/api';

export const ErrorMessageBar: React.FC<
	IMessageBarProps & {
		error?: Api.ErrorResponse;
		errorMessage?: string;
	}
> = (props) => {
	const { t } = useIntl();

	const resolveSpecificErrorMessage = useCallback(
		(errorData: Api.ErrorResponseData) => {
			if (errorData.specificStatusCode) {
				switch (errorData.specificStatusCode) {
					default:
						return t('error__general');
				}
			}
			switch (errorData.status) {
				case 403:
					return t('error__no-access', {
						objectOfRestriction: "this tenant's data",
					});
				default:
					return t('error__general');
			}
		},
		[t]
	);

	const resolveErrorMessage = useCallback(
		(errorMessage: string | undefined) => {
			return errorMessage ? errorMessage : t('error__general');
		},
		[t]
	);

	return (
		<MessageBar
			data-testid="error-message-bar"
			messageBarType={MessageBarType.error}
			{...props}
		>
			{props.error?.data
				? resolveSpecificErrorMessage(props.error.data)
				: resolveErrorMessage(props.errorMessage)}
		</MessageBar>
	);
};
