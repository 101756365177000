import {
	IStackTokens,
	Persona,
	Stack,
	IContextualMenuProps,
	mergeStyleSets,
} from '@fluentui/react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { useIntl } from '@ysoft/react-intl';
import React, { useMemo } from 'react';
import { useUserContext } from '../../libs/userContext';
import TenantsHeaderList from './TenantsHeaderList';
import useAuth from '../../libs/auth';

const spacing: IStackTokens = {
	childrenGap: '1rem',
};

const classes = mergeStyleSets({
	buttonStyles: {
		padding: 0,
		fontWeight: 'bold',
	},
	stackItems: {
		width: 'auto',
	},
	stackItemsPanel: { width: '100%' },
});

export const ActionsPanelElements: React.FC<{ isPanel?: boolean }> = (
	props
) => {
	const { keycloak } = useAuth();
	const { t } = useIntl();
	const userContext = useUserContext();

	const displayName =
		userContext.given_name && userContext.family_name
			? `${userContext.given_name} ${userContext.family_name}`
			: userContext.preferred_username;

	const initials =
		userContext.given_name && userContext.family_name
			? `${userContext.given_name.charAt(0)}${userContext.family_name.charAt(
					0
			  )}`
			: '??';

	const keycloakAuthenticated = keycloak?.authenticated;
	const items = useMemo<IContextualMenuProps>(() => {
		return {
			shouldFocusOnMount: true,
			items: keycloakAuthenticated
				? [
						{
							'data-testid': 'header_logout-button',
							key: 'logoutItem',
							text: t('app__logout'),
							onClick: () => {
								keycloak?.logout();
							},
						},
				  ]
				: [],
		};
	}, [keycloak, keycloakAuthenticated, t]);

	const stackItemClass = props.isPanel
		? classes.stackItemsPanel
		: classes.stackItems;
	return keycloakAuthenticated ? (
		<Stack horizontal={!props.isPanel} tokens={spacing} verticalAlign="center">
			<Stack.Item className={stackItemClass}>
				<TenantsHeaderList />
			</Stack.Item>
			<Stack horizontal tokens={spacing} verticalAlign="center">
				<Stack.Item>
					<Persona
						data-testid="header_user-avatar"
						text={displayName}
						size={24}
						imageUrl={userContext.avatar_url}
						imageInitials={initials}
						hidePersonaDetails={true}
					/>
				</Stack.Item>
				<Stack.Item>
					<PrimaryButton
						data-testid="header_user-menu"
						text={displayName}
						menuProps={items}
						className={classes.buttonStyles}
						onRenderMenuIcon={() => null}
					/>
				</Stack.Item>
			</Stack>
		</Stack>
	) : (
		<div />
	);
};
