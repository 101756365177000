import React, { useContext, useEffect, useState } from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import useAuth from './auth';
import { Api } from '../../types/api';
import { mergeStyleSets, Stack } from '@fluentui/react';
import { useIntl } from '@ysoft/react-intl';

const classNames = mergeStyleSets({
	page_loading_spinner: {
		'margin-top': '10rem',
	},
});

const defaultUser: ExtendedUserInfo = {
	hasTenantId: false,
	isPartner: false,
	preferred_username: 'Unknown User',
};

const userContext = React.createContext<ExtendedUserInfo>(defaultUser);

export const useUserContext = (): ExtendedUserInfo => useContext(userContext);

interface ExtendedUserInfo extends Api.UserInfo {
	isPartner: boolean;
	hasTenantId: boolean;
}

const UserContextProvider: React.FC = (props) => {
	const { keycloak } = useAuth();
	const [user, setUser] = useState<Api.UserInfo>();
	const { t } = useIntl();

	const keycloakAuthenticated = keycloak?.authenticated;
	useEffect(() => {
		if (keycloakAuthenticated) {
			keycloak
				?.loadUserInfo()
				.then((result) => {
					setUser(result as Api.UserInfo);
				})
				.catch((err) => console.log(err));
		}
	}, [keycloak, keycloakAuthenticated]);

	if (user === undefined) {
		return (
			<Stack
				data-testid="platform-spinner"
				horizontalAlign="center"
				verticalAlign="center"
				className={classNames.page_loading_spinner}
			>
				<Spinner size={SpinnerSize.large} label={t('general__loading')} />
			</Stack>
		);
	}

	const customUser: ExtendedUserInfo = {
		...user,
		isPartner: !!(
			user.partner_id &&
			user.roles &&
			user.roles.includes('PartnerSafeQEdgePortal')
		),
		hasTenantId: !!user.tenant_id,
	};

	return (
		<userContext.Provider value={customUser}>
			{props.children}
		</userContext.Provider>
	);
};

export default UserContextProvider;
