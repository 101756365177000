import React, { useState } from 'react';
import { SearchBox, Stack, StackItem } from '@fluentui/react';
import { useIntl } from '@ysoft/react-intl';
import useDebounce from '../hooks/useDebounce';

type ListHeaderWithSearchProps = {
	'data-testid': string;
	HeadingTitle: string;
	SearchBoxPlaceholder?: string;
	searchedTerm: string;
	setSearchedTerm: (searchTerm: string) => void;
};

const setSearchTermDelay = 300;

const HeadingWithSearch: React.FC<ListHeaderWithSearchProps> = (props) => {
	const { t } = useIntl();

	const [tempSearchedTerm, setTempSearchedTerm] = useState<string>(
		props.searchedTerm
	);

	useDebounce(
		() => props.setSearchedTerm(tempSearchedTerm),
		setSearchTermDelay,
		[tempSearchedTerm]
	);

	const onSearchHandler = (onSearchResult: string) => {
		props.setSearchedTerm(onSearchResult);
	};

	return (
		<Stack
			data-testid={props['data-testid']}
			wrap
			horizontal
			tokens={{ childrenGap: 10 }}
		>
			<StackItem>
				<h1 data-testid="title">{props.HeadingTitle}</h1>
			</StackItem>
			<StackItem grow align="center">
				<SearchBox
					data-testid="search-box"
					styles={{ root: { width: '15rem' } }}
					placeholder={props.SearchBoxPlaceholder || t('general__search')}
					defaultValue={props.searchedTerm}
					onClear={() => props.setSearchedTerm('')}
					onChange={(_, term) => setTempSearchedTerm(term || '')}
					onSearch={onSearchHandler}
				/>
			</StackItem>
		</Stack>
	);
};

export default HeadingWithSearch;
