import React, { useState, useEffect } from 'react';
import { Modal, Stack } from '@fluentui/react';
import InstallApplicationSuccessStep from './InstallApplicationSuccessStep';
import InstallApplicationSelectApplication from './InstallApplicationSelectApplication';
import ModalHeader from '../general-components/ModalHeader';
import { useIntl } from '@ysoft/react-intl';
import { Api } from '../../../types/api';
import useApp from '../../useApp';

const InstallApplicationModal: React.FC<{
	onDismiss: () => void;
	isOpen: boolean;
	device?: Api.Device;
	installationSuccess: (
		device: Api.Device,
		application: Api.Application
	) => void;
}> = (props) => {
	const { t } = useIntl();
	const { classes } = useApp();

	const [step, setStep] = useState<{
		name: 'select' | 'success';
		installedApplication?: Api.Application;
	}>({ name: 'select' });
	const closeModal = () => {
		props.onDismiss();
	};
	useEffect(() => {
		if (props.isOpen) {
			setStep({ name: 'select' });
		}
	}, [props.isOpen]);
	return (
		<>
			<Modal isOpen={props.isOpen} onDismiss={closeModal}>
				<Stack
					data-testid="install-application-modal"
					className={classes.modalContent}
					tokens={{ childrenGap: 10 }}
				>
					<ModalHeader
						title={t('install-application-modal__heading')}
						onDismiss={closeModal}
					/>
					{step.name === 'select' && (
						<InstallApplicationSelectApplication
							onDismiss={closeModal}
							device={props.device}
							installationSuccess={(
								device: Api.Device,
								application: Api.Application
							) => {
								setStep({ name: 'success', installedApplication: application });
								props.installationSuccess(device, application);
							}}
						/>
					)}
					{step.name === 'success' && (
						<InstallApplicationSuccessStep
							onDismiss={closeModal}
							applicationName={
								step.installedApplication ? step.installedApplication.name : ''
							}
						/>
					)}
				</Stack>
			</Modal>
		</>
	);
};

export default InstallApplicationModal;
