import { getTheme, INavLink, IStyle, mergeStyles } from '@fluentui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { tabletBreakpoint } from './support/constants';

const theme = getTheme();

const appClass = mergeStyles([
	{
		':after': {
			content: "''",
			display: 'block',
			position: 'absolute',
			top: 0,
			left: 0,
			backgroundImage: "url('/assets/OmniPattern.png')",
			backgroundAttachment: 'fixed',
			width: '100%',
			height: '100%',
			opacity: 0.1,
			zIndex: -1,
		},
	},
	{ position: 'relative', minHeight: '100vh' },
]);

const modalContent = mergeStyles([
	{
		width: 'calc(100vw - 5rem)',
		maxWidth: '40rem',
		padding: '1rem',
	},
]);

const wrapperStyles: IStyle = {
	margin: '0 auto',
	padding: '0rem 1rem 0 1rem',
	maxWidth: '75rem',
	paddingBlockStart: '3rem',
};

wrapperStyles['@media(max-width: ' + tabletBreakpoint + ')'] = {
	paddingBlockStart: 0,
};

const wrapperClass = mergeStyles(wrapperStyles);

const navStackStyles: IStyle = {
	width: '15rem',
};
navStackStyles['@media(max-width: ' + tabletBreakpoint + ')'] = {
	width: '100%',
};
const navStackItemClass = mergeStyles(navStackStyles);

const contentStackItemStyles: IStyle = {
	width: 'calc(100% - 15rem)',
};
contentStackItemStyles['@media(max-width: ' + tabletBreakpoint + ')'] = {
	width: '100%',
};
const contentStackItemClass = mergeStyles(contentStackItemStyles);

const commonNavStyles: IStyle = {
	backgroundColor: theme.palette.white,
	width: '100%',
	paddingBlockEnd: 0,
	paddingBlockStart: '4rem',
};

const commonNavStylesStyle = {
	groupContent: {
		marginBlockEnd: '1rem',
	},
};

const contentClass = mergeStyles({
	contentClass: {
		padding: '0rem 1rem 2px 1rem',
		margin: '0 auto',
		maxWidth: '75rem',
	},
});

const navStyles: IStyle = {
	...commonNavStyles,
	display: 'block',
	height: 'calc(100vh - 4rem)',
	width: '15rem',
	position: 'fixed',
};

navStyles['@media(max-width: ' + tabletBreakpoint + ')'] = {
	display: 'none',
};

const navClass = mergeStyles(navStyles);

const smallScreensNavStyles: IStyle = { ...commonNavStyles, display: 'none' };

smallScreensNavStyles['@media(max-width: ' + tabletBreakpoint + ')'] = {
	display: 'block',
};
const mobileNavClass = mergeStyles(smallScreensNavStyles);

const useApp = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const navigationLinkClick = (
		event: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
		item: INavLink | undefined
	) => {
		event?.preventDefault();
		if (item && item.url !== location.pathname) {
			navigate(item.url);
		}
	};

	return {
		operations: {
			navigationLinkClick,
		},
		classes: {
			appClass,
			commonNavStylesStyle,
			contentClass,
			contentStackItemClass,
			mobileNavClass,
			modalContent,
			navClass,
			navStackItemClass,
			wrapperClass,
		},
	};
};

const ExportFunction = (): ReturnType<typeof useApp> => useApp();
export default ExportFunction;
