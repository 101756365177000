import { useIntl } from '@ysoft/react-intl';
import axios from 'axios';
import React, { useContext, useState, useEffect } from 'react';
import { defaultConfig, Config, dynamicConfigUrl } from './config';

interface ConfigContext {
	config: Config;
}

const configContext = React.createContext<ConfigContext>({
	config: defaultConfig,
});

export const useConfigContext = (): ConfigContext => useContext(configContext);

const ConfigContextProvider: React.FC = (props) => {
	const [config, setConfig] = useState(defaultConfig);
	const { t } = useIntl();

	const [fetchConfigState, setFetchConfigState] = useState<
		'loading' | 'error' | 'success'
	>('loading');
	useEffect(() => {
		axios
			.get(dynamicConfigUrl)
			.then((response) => {
				setConfig((buildConfig) => ({ ...buildConfig, ...response.data }));
				setFetchConfigState('success');
			})
			.catch((e) => {
				if (e.response.status !== 404) {
					console.error('Failed to load config', e.response);
					setFetchConfigState('error');
				} else {
					setFetchConfigState('success');
				}
			});
	}, [setConfig]);

	if (fetchConfigState === 'loading') {
		return <></>;
	} else if (fetchConfigState === 'error') {
		return <div>{t('general__error-loading-runtime-config-file')}</div>;
	}

	return (
		<configContext.Provider value={{ config }}>
			{props.children}
		</configContext.Provider>
	);
};

export default ConfigContextProvider;
